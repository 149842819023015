<template>
  <div>
    <Filters
      type="card"
      :filter-model="filterModel"
      @init="filterChange"
      @on-apply="onApplyFilters"
    />
    <SessionsTableV1
      v-if="report"
      :report="report"
      :interval="interval"
      :services="services"
      :versions="versions"
    />
  </div>
</template>

<script>
import konecta from '@/services/konecta'

export default {
  components: {
    SessionsTableV1: () =>
      import('../charts/sessions_table_v1/SessionsTableV1.vue'),
    Filters: () => import('../filters/Filters.vue')
  },
  data() {
    return {
      report: null,
      reports: [],
      filterModel: [],
      services: [],
      versions: [],
      interval: {}
    }
  },
  methods: {
    parseRange(values) {
      const from = values[0]
      const to = values[1]

      return { from, to }
    },
    onApplyFilters(filters) {
      const filtersTypes = Object.keys(filters)
      filtersTypes.forEach(filter => this.filterChange(filters[filter]))
    },
    async filterChange(filter) {
      switch (filter.type) {
        case 'bot':
          this.services = filter.value.map(bot => bot.service)
          this.versions = filter.value.map(bot => bot._id)
          this.$router.push({
            query: { services: this.services, versions: this.versions }
          })
          break
        case 'date':
          if (filter.value[0] && filter.value[1]) {
            this.interval = this.parseRange(filter.value)
          } else {
            // this.interval = this.weekInterval()
          }
          break
        // case 'report':
        //   this.filteredReports = filter.value.map(report => report.name)
        //   break
        default:
          break
      }

      // await this.getLastUpdate()
    },
    weekInterval() {
      const to = new Date()
      let from = new Date(to.getTime())
      from.setDate(from.getDate() - 7)
      return {
        from: new Date(from.setHours(0, 0, 0, 0)),
        to: new Date(to.setHours(0, 0, 0, 0))
      }
    },
    // parseChartsOptions() {
    //   const chartOptions = []
    //   this.reports.forEach(report => {
    //     // let reportName = report.name[1].text //.toLowerCase().replace(/ /g, '_')

    //     chartOptions.push({
    //       name: report.name[1].text,
    //       es: report.name[0].text,
    //       en: report.name[1].text
    //     })
    //   })
    //   return chartOptions
    // },
    getReport(cb) {
      this.$vs.loading()
      konecta
        .get(
          '/report/5ec2960e4a0102ec03056bf5?select[name]=true&select[description]=true&select[reportType]=true&select[_extra]=true'
        )
        .then(response => {
          this.report = response.data
          this.reports = [this.report]
        })
        .catch(error => {
          this.$vs.notify({
            title: 'Error',
            text: '',
            color: 'danger',
            position: 'top-right'
          })
          console.error(error)
        })
        .finally(() => {
          this.$vs.loading.close()
          return cb()
        })
    }
  },
  mounted() {
    this.getReport(() => {
      this.interval = this.weekInterval()
      // const chartsOptions = this.parseChartsOptions()

      this.filterModel = [
        {
          name: 'bots',
          component: 'BotFilter',
          width: 6,
          props: {
            allowEmpty: false,
            startAll: true
          }
        },
        {
          name: 'dates',
          component: 'DateRangeFilter',
          width: 6,
          props: {
            value: this.interval
          }
        }
      ]
    })
  }
}
</script>

<style>
h6 {
  margin-bottom: 1rem;
}
</style>
